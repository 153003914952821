import React from "react";
/*import Draw from './draw'*/
import LetterSoupClass from "./LetterSoupClass";
import Tools from "./Tools";
import "./../games-Styles.scss";


class LetterSoup extends React.Component {
	canvasRef;
	canvas;
	context;
	animationFrameId;
	frameCount = 0;

	currentStep;
	config;

	scaleRatio = {
		xRatio: 0,
		YRatio: 0
	};

	soup;

	//Mouse variables
	mouse = {
		x: 0,
		y: 0,
		click: false,
		enter: false
	};

	intervalTimer = null;

	constructor(props) {
		super(props);

		this.canvasRef = React.createRef();

		this.config = {
			width: 1000,
			height: 1000,
			size: null,
			alphabet: null,
			rules: null
		};

		this.renderCanvas = this.renderCanvas.bind(this);

		this.state = {
			timer: {
				minutes: "00",
				secons: "00"
			},
			words: {
				wordsToSearch: [],
				wordsSearched: []
			},
			start: false,
			gameEnd: false
		};
	}

	componentDidMount() {
		//this.currentStep = this.props.currentStep;
		let matrix = this.props.data.matrix.props;
		let config = {size:matrix.size, alphabet: matrix.alphabet, 
			"rules": matrix.rules
		}
		this.config = {
			width: 1000,
			height: 1000,
			size: config.size.width,
			alphabet: config.alphabet,
			rules: config.rules
		};

		this.startGame();
	}

	startGame = () => {
		this.setState({ start: true });
		this.canvas = this.canvasRef.current;
		this.context = this.canvasRef.current.getContext("2d");
		if (this.context) {
			let letters =  Array.from(this.props.data.accepted_words, (element) =>  element.toLowerCase());
			this.soup = new LetterSoupClass(this.context, this.config, letters, this.gameEnd);
			this.soup.drawLettersSearched(this.toFindedWord);
			//We generate the boxes of the alphabet soup
			let lettersBoxes = Tools.generateBoxes(this.config, letters);

			this.soup.intertLetters(lettersBoxes);

			//We launch the thread to render the canvas
			this.renderCanvas();
			this.initTimer();
		}
	};

	componentWillUnmount() {
		if(this.intervalTimer){
			clearInterval(this.intervalTimer);
		}
	}

	initTimer() {
		this.intervalTimer = setInterval(() => {
			const { timer } = this.state;

			let secons = (parseInt(timer.secons) + 1) % 60;
			let minutes = secons === 0 ? parseInt(timer.minutes) + 1 : parseInt(timer.minutes);

			if (secons < 10) secons = "0" + secons;
			if (minutes < 10) minutes = "0" + minutes;
			this.setState({
				timer: {
					secons: secons,
					minutes: minutes
				}
			});
		}, 1000);
	}

	toFindedWord = (wordsToSearch = [], wordsSearched = []) => {
		this.setState({
			words: {
				wordsToSearch,
				wordsSearched
			}
		});
	};

	renderCanvas() {
		this.frameCount++;
		//this.clearCanvas(this.context);

		//Calculamos Xratio
		const rect = this.canvas.getBoundingClientRect();
		this.scaleRatio.xRatio = this.canvas.width / this.config.width;
		this.scaleRatio.yRatio = this.canvas.width / this.config.width;

		//We update the canvas
		this.soup.updateStatus(this.mouse, this.scaleRatio);

		//We draw the canvas
		this.soup.draw();

		//We call the main Canvas loop again
		if (this.state.gameEnd) return;
		this.animationFrameId = window.requestAnimationFrame(this.renderCanvas);
	}

	handleMouseDown = (event) => {
		event.preventDefault();
		this.mouse.click = true;
	};

	handleMouseUp = () => {
		this.mouse.click = false;
	};

	handleMouseMove = (event) => {
		const rect = this.canvas.getBoundingClientRect();

		const x = ((event.clientX - rect.left) / rect.width) * this.config.width;
		const y = ((event.clientY - rect.top) / rect.height) * this.config.height;

		this.mouse.x = x;
		this.mouse.y = y;
	};

	gameEnd = () => {
		this.setState({ gameEnd: true });
		clearInterval(this.intervalTimer);
		this.props.end({rightWords: this.state.words.wordsSearched});
	};

	mouseOnEnter = () => {
		this.mouse.enter = true;
	};

	mouseOnLeave = () => {
		this.mouse.enter = false;
	};

	smartphoneTouchStart = (e) => {
		e.preventDefault();
	};

	smartphoneTouchEnd = (e) => {
		e.preventDefault();
		this.mouse.click = false;
		this.mouse.enter = false;
	};

	smartphoneTouchMove = (e) => {
		e.preventDefault();
		this.mouse.click = true;
		this.mouse.enter = true;
		this.handleMouseMove({
			clientX: e.touches[0].clientX,
			clientY: e.touches[0].clientY
		});
	};

	scroll = (e) => {
		e.preventDefault();
		if (this.mouse.enter) e.preventDefault();
	};

	render() {
		let pointsWords = this.state.words.wordsToSearch.map((word) => {
			if (this.state.words.wordsSearched.includes(word)) {
				return (
					<p className='finded' key={word}>
						⬤
					</p>
				);
			} else {
				return (
					<p className='not-finded' key={word}>
						⬤
					</p>
				);
			}
		});

		return (
			<>
				<div className='alfabetSoup' onScroll={this.scroll}>
					<div className='game'>
						<div className='gameCanvas'>
							<canvas
								ref={this.canvasRef}
								width={this.config.width}
								height={this.config.height}
								onMouseDown={this.handleMouseDown}
								onMouseUp={this.handleMouseUp}
								onMouseMove={this.handleMouseMove}
								onMouseLeave={this.mouseOnLeave}
								onMouseEnter={this.mouseOnEnter}
								onTouchStart={this.smartphoneTouchStart}
								onTouchEnd={this.smartphoneTouchEnd}
								onTouchMoveCapture={this.smartphoneTouchMove}
								className='canvasRef'
							></canvas>
						</div>
						<div className='gameInfo'>
							<div className='timer'>
								<samp className='mitutes'>{this.state.timer.minutes}</samp>:<samp className='secons'>{this.state.timer.secons}</samp>
							</div>
							<div className='words'>
								<div className='marker'>{pointsWords}</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default LetterSoup;
