import React from "react";
import ReactLoading from 'react-loading';

import LetterSoup from "./games/AlphabetSoup/LetterSoup";
import HuntWords from "./games/HutWords/huntWords";
import SeekAndFind from "./games/SeekAndFind/SeekAndFind"

import './App.scss'


class App extends React.Component {
  time = {
    timeStart: null,
    timeEnd: null
  }

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      showResult: false,
      editionMode: new URLSearchParams(window.location.search).get("is_editable") === "true" ? true : false
    };

  }

  endFunction = (event) => {
    this.time.timeEnd = Date.now();
    event.time = this.time;
    window.parent.postMessage({ message: "gameEnd", data: event }, '*');
  }

  editTableFunction = (infoToEdit) => {
    window.parent.postMessage({ message: "infoToEdit", data: infoToEdit }, '*');
  }

  getInfo() {
    setTimeout(() => {
      if (!this.state.data) {
        window.parent.postMessage({ message: "getInfo", concept: 'onload' }, '*');
      } else {
        this.getInfo();
      }
    }, 1000);
  }

  componentDidMount() {
    // setTimeout(this.endFunction, 2000)
    let offset = this.state.editionMode ? 40 : 45;
    window.parent.postMessage({ message: "heightScroll", data: document.body.scrollHeight + offset, concept: 'onload' }, '*');
    this.getInfo();

    window.addEventListener("message", ({ data }) => {
      if (data.message === 'resize') {
        window.parent.postMessage({ message: "heightScroll", data: document.body.scrollHeight + offset, concept: 'resize' }, '*');
      }

      if (data.message === 'charge-info') {
        this.time.timeStart = Date.now();
        this.setState({ data: data.data });
        window.parent.postMessage({ message: "heightScroll", data: document.body.scrollHeight + offset, concept: 'resize' }, '*');
      }

      if (data.message === 'show-result') {
        this.setState({ showResult: true })
      }
    });
  }

  onSelectedSeekAndFind = (selectedPoint) => {
    window.parent.postMessage({ message: "alreadyBeenSelected", data: selectedPoint }, '*');
  }

  render() {
    let html = (
      <>
        <div className="loaling-container">
          <ReactLoading type={'spin'} color={'rgb(0,126,123)'} width='40%' className="container" />
        </div>
      </>
    );
    const data = this.state.data;
    if (data) {
      if (data.type === 'word-search') {
        html = <LetterSoup
          end={this.endFunction}
          data={data}
        />
      } else if (data.type === 'word-catcher') {
        html = <HuntWords
          end={this.endFunction}
          data={data}
        />
      } else if (data.type === 'seek-and-find') {
        html = <SeekAndFind
          onedit={this.editTableFunction}
          onselected={this.onSelectedSeekAndFind}
          showResult={this.state.showResult}
          data={data}
          editionMode={this.state.editionMode}
        />
      } else {
        html = <h2>Insert compatible game</h2>
      }
    }

    return html;
  }
}

export default App;
