import React from 'react'
import SeekAndFindClass from "./SeekAndFindClass";

import {
	useParams
} from "react-router-dom";
import InputRange from '../../components/inputRange/InputRange';

class SeekAndFind extends React.Component {
	canvasRef;
	canvas;
	context;
	animationFrameId;
	frameCount = 0;
	timerShow
	currentStep;
	config;

	scaleRatio = {
		xRatio: 0,
		YRatio: 0
	};

	game = null;

	//Mouse variables
	mouse = {
		x: 0,
		y: 0,
		click: false,
		enter: false
	};

	targetRadiusEditionMode = {
		firstRatio: 0,
		secondRatio: 0
	}

	intervalTimer = null;

	targetCoords = null;

	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();
		this.renderCanvas = this.renderCanvas.bind(this);
		this.config = {
			editionMode: props.editionMode,
			width: 1000,
			height: 1000,
			imageBackground: props.data.image_url ? props.data.image_url : props.mediaPreview + props.data.image
		};
		console.log("url", props.data.image_url ? props.data.image_url : props.mediaPreview + props.data.image)
		this.state = {
			timer: {
				minutes: "00",
				secons: "00"
			},
			words: {
				wordsToSearch: [],
				wordsSearched: []
			},
			start: false,
			gameEnd: false,
			valueFirtsCircle: 100,
			valueSecondCircle: 150
		};

		const { options, targets } = props.data;
		options.sort((a, b) => a.order - b.order);

		this.targetCoords = {
			x: targets[0].area.x,
			y: targets[0].area.y,
			firstRatio: options[0].area.radius,
			secondRatio: options[1].area.radius
		}

		this.targetRadiusEditionMode.firstRatio = options[0].area.radius;
		this.targetRadiusEditionMode.secondRatio = options[1].area.radius;

	}

	componentDidMount() {
		//this.currentStep = this.props.currentStep;

		this.startGame();
	}

	startGame = () => {
		this.setState({ start: true });
		this.canvas = this.canvasRef.current;
		this.context = this.canvasRef.current.getContext("2d");
		if (this.context) {
			this.game = new SeekAndFindClass(this.context, this.config, this.targetCoords, this.gameEnd, this.setPointEditable);

			//We launch the thread to render the canvas
			this.renderCanvas();
			if (!this.config.editionMode) {
				this.initTimer();
			}

		}
	};

	setPointEditable = point => {
		this.props.onedit(point)
	}

	componentWillUnmount() {
		if (this.intervalTimer) {
			clearInterval(this.intervalTimer);
		}
	}

	initTimer() {
		this.intervalTimer = setInterval(() => {
			const { timer } = this.state;

			let secons = (parseInt(timer.secons) + 1) % 60;
			let minutes = secons === 0 ? parseInt(timer.minutes) + 1 : parseInt(timer.minutes);

			if (secons < 10) secons = "0" + secons;
			if (minutes < 10) minutes = "0" + minutes;
			this.setState({
				timer: {
					secons: secons,
					minutes: minutes
				}
			});
		}, 1000);
	}

	toFindedWord = (wordsToSearch = [], wordsSearched = []) => {
		this.setState({
			words: {
				wordsToSearch,
				wordsSearched
			}
		});
	};

	renderCanvas() {
		this.frameCount++;
		//this.clearCanvas(this.context);

		//Calculamos Xratio
		const rect = this.canvas.getBoundingClientRect();
		this.scaleRatio.xRatio = this.canvas.width / this.config.width;
		this.scaleRatio.yRatio = this.canvas.width / this.config.width;

		//We update the canvas
		this.game.updateStatus(this.mouse, this.scaleRatio, this.props.showResult, this.targetRadiusEditionMode);

		//We draw the canvas
		this.game.draw();

		//We call the main Canvas loop again
		if (this.state.gameEnd) return;
		this.animationFrameId = window.requestAnimationFrame(this.renderCanvas);
	}

	handleMouseDown = (event) => {
		event.preventDefault();
		this.mouse.click = true;
	};

	handleMouseUp = () => {
		this.mouse.click = false;
	};

	handleMouseMove = (event) => {
		const rect = this.canvas.getBoundingClientRect();

		const x = ((event.clientX - rect.left) / rect.width) * this.config.width;
		const y = ((event.clientY - rect.top) / rect.height) * this.config.height;

		this.mouse.x = x;
		this.mouse.y = y;
	};

	gameEnd = (selectedPoint) => {
		this.props.onselected(selectedPoint);
	};

	mouseOnEnter = () => {
		this.mouse.enter = true;
	};

	mouseOnLeave = () => {
		this.mouse.enter = false;
	};

	smartphoneTouchStart = (e) => {
		e.preventDefault();
	};

	smartphoneTouchEnd = (e) => {
		e.preventDefault();
		this.mouse.click = false;
		this.mouse.enter = false;
	};

	smartphoneTouchMove = (e) => {
		e.preventDefault();
		this.mouse.click = true;
		this.mouse.enter = true;
		this.handleMouseMove({
			clientX: e.touches[0].clientX,
			clientY: e.touches[0].clientY
		});
	};

	scroll = (e) => {
		e.preventDefault();
		if (this.mouse.enter) e.preventDefault();
	};

	///Pruebas

	firtsCircleChange = (e) => {
		this.setState({ valueFirtsCircle: e.target.value });
	}
	secondCircleChange = (e) => {
		this.setState({ valueSecondCircle: e.target.value });
	}

	onchangeInputFirtRatio = (e) => {
		console.log(e.target.value)
	}

	render() {

		return (
			<>
				<div className='alfabetSoup' onScroll={this.scroll}>
					<div className='game'>
						<div className='gameCanvas'>
							<canvas
								ref={this.canvasRef}
								width={this.config.width}
								height={this.config.height}
								onMouseDown={this.handleMouseDown}
								onMouseUp={this.handleMouseUp}
								onMouseMove={this.handleMouseMove}
								onMouseLeave={this.mouseOnLeave}
								onMouseEnter={this.mouseOnEnter}
								onTouchStart={this.smartphoneTouchStart}
								onTouchEnd={this.smartphoneTouchEnd}
								onTouchMoveCapture={this.smartphoneTouchMove}
								className='canvasRef'
							></canvas>
						</div>
						{this.config.editionMode ?
							(
								<div className="edition-part">
									<InputRange
										min={30}
										max={500}
										value={this.targetCoords.firtsRatio}
										onChange={event => this.targetRadiusEditionMode.firstRatio = parseInt(event.target.value)}
										className="input-range"
									/>
									<InputRange
										min={30}
										max={500}
										value={this.targetCoords.secondRatio}
										onChange={event => this.targetRadiusEditionMode.secondRatio = parseInt(event.target.value)}
										className="input-range" />
								</div>
							) : (
								<div className='gameInfo'>
									<div className='timer'>
										<samp className='mitutes'>{this.state.timer.minutes}</samp>:<samp className='secons'>{this.state.timer.secons}</samp>
									</div>
								</div>
							)
						}
					</div>
				</div>
			</>
		);
	}
}

export default SeekAndFind
