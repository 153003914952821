import React from "react";
/*import Draw from './draw'*/
import HuntWordsClass from "./HuntWordsClass";
import "./../games-Styles.scss";
//import { default as jsonConfig } from "./config";

const config = {
	config: {
		"size": {
			"width": 10,
			"heigth": 10
		},
		"alphabet": "EN",
		"rules": {
			"allow_inverse": false,
			"allow_vertical": true,
			"allow_horizontal": true,
			"allow_diagonal": true
		}
	},
	title: "Look for these 4 fruits: banana, Apple, Pear, Lemon, Melon, Coconut, Grapes",
	timer: 120,
	words: {
		kindWords: [
			"Banana",
			"Apple",
			"Pear",
			"Lemon",
			"Melon",
			"Coconut",
			"Grapes"
		],
		badWords: [
			"Pizza",
			"Onion",
			"Sandwich",
			"Cheese",
			"Butter",
			"Soup",
			"Egg",
			"Pasta",
			"Tomato",
			"Lettuge",
			"Butter",
			"Bread"
		]
	}
}


class HuntWords extends React.Component {
	canvasRef;
	canvas;
	context;
	animationFrameId;
	frameCount = 0;

	scaleRatio = {
		xRatio: 0,
		YRatio: 0
	};

	config;

	gameClass = null;

	//Mouse variables
	mouse = {
		x: 0,
		y: 0,
		click: false,
		enter: false
	};

	intervalTimer = null;

	constructor(props) {
		super(props);

		this.canvasRef = React.createRef();
		//let testData =' props.testData[props.currentStep - 1]';
		const data = this.props.data;

		this.config = {
			width: 1000,
			height: 1000,
			background_image: data.props.background_image?.image ?? data.props.background_image,
			is_external: data.props.background_image?.is_external || false,
			word_color: data.props?.word_color || undefined,
		};

		this.renderCanvas = this.renderCanvas.bind(this);



		let time = data.props.time;
		this.state = {
			timer: {
				minutes: "00",
				secons: "00",
				totalInSeconds: time === -1 ? 0 : time,
				isMaximumTime: time === -1 ? false : true
			},
			words: {
				wordsToSearch: [],
				wordsSearched: []
			},
			start: false,
			gameEnd: false
		};
	}

	startGame = () => {
		this.setState({ start: true });
		this.canvas = this.canvasRef.current;
		this.context = this.canvasRef.current.getContext("2d");
		let words = {
			kindWords: this.props.data.accepted_words,
			badWords: this.props.data.unaccepted_words
		}
		if (this.context) {
			this.gameClass = new HuntWordsClass(this.context, this.config, words, this.gameEnd, this.toHuntWord);
			this.renderCanvas();
			this.initTimer();
		}
	};

	componentDidMount() {
		this.startGame();
	}

	initTimer() {
		this.intervalTimer = setInterval(() => {
			const { timer } = this.state;
			if (timer.isMaximumTime) {
				if (timer.totalInSeconds <= 0) {
					timer.totalInSeconds = 0;
					if (!this.state.gameEnd) {
						this.gameClass.onEndGame();
					}
				} else {
					timer.totalInSeconds--;
				}
			} else {
				timer.totalInSeconds++;
			}

			let secons = parseInt((timer.totalInSeconds % 60).toFixed(0));
			let minutes = parseInt(timer.totalInSeconds / 60);

			if (secons < 10) secons = "0" + secons;
			if (minutes < 10) minutes = "0" + minutes;
			this.setState({
				timer: {
					secons: secons,
					minutes: minutes,
					totalInSeconds: timer.totalInSeconds,
					isMaximumTime: timer.isMaximumTime
				}
			});
		}, 1000);
	}

	toFindedWord = (wordsToSearch = [], wordsSearched = []) => {
		this.setState({
			words: {
				wordsToSearch,
				wordsSearched
			}
		});
	};

	renderCanvas() {
		this.frameCount++;
		//this.clearCanvas(this.context);

		//Calculamos Xratio
		this.scaleRatio.xRatio = this.canvas.width / this.config.width;
		this.scaleRatio.yRatio = this.canvas.width / this.config.width;

		//We update the canvas
		this.gameClass.updateStatus(this.mouse, this.scaleRatio);

		//We draw the canvas
		this.gameClass.draw();

		//We call the main Canvas loop again
		this.animationFrameId = window.requestAnimationFrame(this.renderCanvas);
	}

	toHuntWord = (wordsToSearch = [], wordsSearched = []) => {
		this.setState({
			words: {
				wordsToSearch,
				wordsSearched
			}
		});
	};

	handleMouseDown = (event) => {
		event.preventDefault();
		this.mouse.click = true;
	};

	handleMouseUp = () => {
		this.mouse.click = false;
	};

	handleMouseMove = (event) => {
		const rect = this.canvas.getBoundingClientRect();

		const x = ((event.clientX - rect.left) / rect.width) * this.config.width;
		const y = ((event.clientY - rect.top) / rect.height) * this.config.height;

		this.mouse.x = x;
		this.mouse.y = y;
	};

	gameEnd = () => {
		this.setState({ gameEnd: true });
		// if (this.intervalTimer) clearInterval(this.intervalTimer);
		this.props.end({ rightWords: this.state.words.wordsSearched });
	};

	mouseOnEnter = () => {
		this.mouse.enter = true;
	};

	mouseOnLeave = () => {
		this.mouse.enter = false;
	};

	smartphoneTouchStart = (e) => {
		e.preventDefault();
	};

	smartphoneTouchEnd = (e) => {
		e.preventDefault();
		this.mouse.click = false;
		this.mouse.enter = false;
	};

	smartphoneTouchMove = (e) => {
		e.preventDefault();
		this.mouse.click = true;
		this.mouse.enter = true;
		this.handleMouseMove({
			clientX: e.touches[0].clientX,
			clientY: e.touches[0].clientY
		});
	};

	scroll = (e) => {
		e.preventDefault();
		if (this.mouse.enter) e.preventDefault();
	};

	render() {
		let pointsWords = this.state.words.wordsToSearch.map((word) => {
			if (this.state.words.wordsSearched.includes(word)) {
				return <p className='finded'>⬤</p>;
			} else {
				return <p className='not-finded'>⬤</p>;
			}
		});

		return (
			<>
				<div className='alfabetSoup' onScroll={this.scroll}>
					<div className='game'>
						<div className='gameCanvas'>
							<canvas
								ref={this.canvasRef}
								width={this.config.width}
								height={this.config.height}
								onMouseDown={this.handleMouseDown}
								onMouseUp={this.handleMouseUp}
								onMouseMove={this.handleMouseMove}
								onMouseLeave={this.mouseOnLeave}
								onMouseEnter={this.mouseOnEnter}
								onTouchStart={this.smartphoneTouchStart}
								onTouchEnd={this.smartphoneTouchEnd}
								onTouchMoveCapture={this.smartphoneTouchMove}
								className='canvasRef'
							></canvas>
						</div>
						<div className='gameInfo'>
							<div className='timer'>
								<samp className='mitutes'>{this.state.timer.minutes}</samp>:
								<samp className='secons'>{this.state.timer.secons}</samp>
							</div>
							<div className='words'>
								<div className='marker'>{pointsWords}</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default HuntWords;
