

const caracterEn = "ABCDEFGHIJKLMNPQRTUVWXYZ";
//const caracterEn = "*"
const caracterEs = "ABCDEFGHIJKLMNÑPQRTUVWXYZ";

let ramdonBox = (maxX, maxY) => {
    return {
        x: Math.floor(Math.random() * maxX),
        y: Math.floor(Math.random() * maxY)
    }
}

const Tools = {
    generateBoxes: (config = { width: 500, height: 500, size: 10 }, words = []) => {
        let boxes = [];


        for (let y = 0; y < config.size; y++) {
            let colums = []
            for (let x = 0; x < config.size; x++) {
                let box = {
                    x: x,
                    y: y,
                    letter: "*"
                }
                colums.push(box)
            }
            boxes.push(colums);
        }

        for (let i = 0; i < words.length; i++) {
            let word = words[i];
            //We check that the word can be turned around
            if (config.rules.allow_inverse) {
                //We decide randomly if we turn the word around
                if (Math.floor(Math.random() * 100) > 50) {
                    let reverseWord = "";
                    let x = word.length;

                    while (x >= 0) {

                        reverseWord = reverseWord + word.charAt(x);
                        x--;
                    }
                    word = reverseWord;
                }
            }

            let flagLetterInserted = false;
            let errorToInsertLetter = false;

            while (!flagLetterInserted || errorToInsertLetter) {
                flagLetterInserted = false;
                errorToInsertLetter = false;

                let newBoxes = JSON.parse(JSON.stringify(boxes));

                let direction = Math.floor(Math.random() * 3);

                switch (direction) {
                    case 0: //Horizontal         
                        if (config.rules.allow_horizontal) {
                            let initialBox = ramdonBox(
                                config.size - (word.length - 1),
                                config.size
                            )

                            for (let i = 0; i < word.length; i++) {
                                let letter = word.charAt(i, 1).toUpperCase();
                                if (newBoxes[initialBox.y][initialBox.x + i].letter === "*" || newBoxes[initialBox.y][initialBox.x + i].letter === letter) {
                                    newBoxes[initialBox.y][initialBox.x + i].letter = letter;
                                    if (i === (word.length - 1)) {
                                        flagLetterInserted = true;
                                    }
                                } else {
                                    errorToInsertLetter = true;
                                }
                            }
                        }
                        break;
                    case 1: //Vertical
                        if (config.rules.allow_vertical) {
                            let initialBox = ramdonBox(
                                config.size,
                                config.size - (word.length - 1)
                            )

                            for (let i = 0; i < word.length; i++) {
                                let letter = word.charAt(i, 1).toUpperCase();
                                if (newBoxes[initialBox.y + i][initialBox.x].letter === "*" || newBoxes[initialBox.y + i][initialBox.x].letter === letter) {
                                    newBoxes[initialBox.y + i][initialBox.x].letter = letter;
                                    if (i === (word.length - 1)) {
                                        flagLetterInserted = true;
                                    }
                                } else {
                                    errorToInsertLetter = true;
                                }
                            }
                        }
                        break;
                    case 2: // Diagonal descentente
                        if (config.rules.allow_diagonal) {
                            let initialBox = ramdonBox(
                                config.size - (word.length - 1),
                                config.size - (word.length - 1)
                            )

                            for (let i = 0; i < word.length; i++) {
                                let letter = word.charAt(i, 1).toUpperCase();
                                if (newBoxes[initialBox.y + i][initialBox.x + i].letter === "*" || newBoxes[initialBox.y + i][initialBox.x + i].letter === letter) {
                                    newBoxes[initialBox.y + i][initialBox.x + i].letter = letter;
                                    if (i === (word.length - 1)) {
                                        flagLetterInserted = true;
                                    }
                                } else {
                                    errorToInsertLetter = true;
                                }
                            }
                        }
                        break;
                    case 3:
                        console.log("diagonal descendente")
                        break;
                }

                if (flagLetterInserted && !errorToInsertLetter) {
                    boxes = newBoxes;
                }
            }
        }

        for (let y = 0; y < boxes.length; y++) {
            for (let x = 0; x < boxes.length; x++) {
                if (boxes[y][x].letter === '*') {
                    boxes[y][x].letter = caracterEn.charAt(Math.floor(Math.random() * caracterEn.length));
                }
            }
        }

        return boxes;
    },
    boxToCoordinates(box, config = { width: 500, height: 500, size: 15 }) {
        let boxSize = config.width / config.size;
        let coordinates = {
            x: boxSize * box.x,
            y: boxSize * box.y
        }

        return coordinates;
    },
    coordinatesToBox(coodinates, boxSize) {
        let box = {
            x: Math.floor(coodinates.x / boxSize),
            y: Math.floor(coodinates.y / boxSize)
        }

        return box;
    },
    isPosibleSelected(word) {
        let { fistLetter, lastLetter } = word;
        if (fistLetter.x === lastLetter.x || fistLetter.y === lastLetter.y) {
            return true;
        } else {
            let distanceX = Math.abs(fistLetter.x - lastLetter.x);
            let distanceY = Math.abs(fistLetter.y - lastLetter.y);
            return distanceY === distanceX;
        }
    }
}
export default Tools;